.logout-button, .login-button {
    display: inline-block;
    cursor: pointer;
    color: black !important;
    text-decoration: none !important;
}
.alt-link {
    color: black !important;
    text-decoration: none !important;
    font-weight: 600;
}
.header {
    border-bottom: solid black 3px;
    margin-top: 10px;
    margin-bottom: 8px;
}
.site-title {
    display: inline-block;
    font-size: 14pt;
    font-weight: bold;
    color: black !important;
    text-decoration: none !important;
}
.user-status {
    float: right;
}
.nav {
    margin-bottom: 25px;
}
.nav-button {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    border: solid 2px black;
    color: black !important;
    font-weight: 600;
    background-color: white;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.selected {
    background-color: black;
    color: white !important;
}
.saving-button {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    border: solid 2px white;
    font-weight: 600;
    background-color: white;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.nav-button-link {
    text-decoration: none !important;
    color: black !important;
}
.nav-button:hover {
    background-color: rgba(0, 0, 0, 0.98);
    color: white !important;
    text-decoration: none !important;
}
.nav-button-danger:hover {
    background-color: red;
    border: solid 2px red;
}
@import url(https://fonts.googleapis.com/css2?family=Itim&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-title {
  display: inline-block;
  font-size: 14pt;
  font-weight: bold;
}
.user-status {
  float: right;
}
.alt-link {
  color: black !important;
  text-decoration: none !important;
  font-weight: 600;
}
.display-name, .logout-button {
  text-decoration: underline;
  cursor: pointer;
}
.page-title {
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 10px;
}
.logout-button, .login-button {
  display: inline-block;
  cursor: pointer;
}





/* Upload */
.img-display-upload-container {
  height: 100%;
  width: 100%;
  border: solid 1px #c3c3c3;
  text-align: center;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-img-label {
  font-family: 'Permanent Marker';
  font-size: 2em;
  color: white;
  width: 100%;
  height: 100%;
  padding-top: 125px;
  cursor: pointer;
  font-weight: 600;
}
.upload-img-label-hidden {
  position: relative;
  top: -315px;
  width: 100%;
  height: 100%;
}
.upload-label-font {
  font-weight: 600;
}
.upload-img-background {
  width: 420px;
  height: 315px;
  background-color: black;
}
@media only screen and (max-width: 768px) {
    .upload-img-background {
      width: 352px;
      height: 264px;
    }
    .upload-img-label {
        padding-top: 95px;
    }
    .upload-img-label-hidden {
      top: -264px;
    }
}
.required {
  color: red;
}




/* Photo */
.photo-fact {
  margin-top: 10px;
  border-bottom: solid 1px black;
}
.photo-fact-name {
  font-weight: 400;
  margin-right: 6px;
}
.photo-fact-detail {
  font-family: 'Itim', cursive;
  font-size: 1.2em;
}
.photo-note-book {
  background: lightyellow;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  box-shadow: 5px 6px 13px 2px grey;
}
.photo-img {
  box-shadow: 5px 6px 13px 2px grey;
  cursor: pointer;
}
.admin-panel {
  margin-top: 20px;
}
.modal-dialog {
  margin: 0px !important;
  border: none !important;
  max-width: initial;
}
.modal-content {
  width: 100% !important;
  /*height: 100% !important;*/
  margin: 0px !important;
  border: none !important;
  max-width: initial;
  background-color: black;
}
.modal-backdrop.fade {
  opacity: 1 !important;
}
.photo-card {
  min-height: 547.5px;
}
@media only screen and (max-width: 768px) {
  .photo-note-book {
    box-shadow: 5px 6px 12px 1px grey;
  }
  .photo-img {
    box-shadow: 5px 6px 12px 1px grey;
  }
  .photo-card {
    min-height: 1px;
  }
}

.app-min-height {
  min-height: 585px;
}
.drawer-container {
    margin-left: 10px;
    justify-content: center;
}
.polaroid-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-right: 15px;
}
.img-container {
    max-width: 520px;
    max-height: 500px;
    border: solid 1px lightgray;
    margin: 10px;
    padding: 20px;
    box-shadow: 7px 6px 12px 5px grey;
    background: white;
}
.img-display {
    width: 480px;
    height: 360px;
}
.img-description {
    height: 100px;
    position: relative;
    font-family: 'Permanent Marker', cursive;
    color: black;
    margin-left: 15px;
    margin-right: 15px;
}
.img-description-text-sm {
    position: absolute;
    margin-top: 36px;
}
.img-description-text-md {
    position: absolute;
    margin-top: 22px;
}
.loading-wheel {
    font-size: 20pt;
    font-weight: 600;
    height: 320px;
    margin-top: 215px;
}
.text-size-md {
    font-size: 1.6em;
}
.text-size-sm {
    font-size: 1.5em;
}
.text-size-xs {
    font-size: 1.3em;
}
@media only screen and (max-width: 768px) {
    .text-size-md {
        font-size: 1.4em;
    }
    .text-size-sm {
        font-size: 1.2em;
    }
    .text-size-xs {
        font-size: 1.0em;
    }
    .drawer-container {
        margin-left: 0px;
    }
}
@media only screen and (max-width: 600px) {
    .img-container {
        box-shadow: 3px 4px 12px 1px grey;
        padding: 15px;
    }
    .img-display {
        width: 320px;
        height: 240px;
    }
    .img-description-text-sm {
        margin-top: 25px;
    }
    .img-description-text-md {
        margin-top: 20px;
    }
}
.nav {
    margin-bottom: 25px;
}
.nav-button {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    border: solid 2px black;
    color: black !important;
    font-weight: 600;
    background-color: white;
    -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
    user-select: none; /* Standard */
}
.selected {
    background-color: black;
    color: white !important;
}
.saving-button {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    border: solid 2px white;
    font-weight: 600;
    background-color: white;
    -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
    user-select: none; /* Standard */
}
.nav-button-link {
    text-decoration: none !important;
    color: black !important;
}
.nav-button:hover {
    background-color: rgba(0, 0, 0, 0.98);
    color: white !important;
    text-decoration: none !important;
}
.nav-button-danger:hover {
    background-color: red;
    border: solid 2px red;
}
.explore-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}
.explore-card {
    margin-bottom: 15px;
}
.explore-place-tag {
    border: solid 2px black;
    border-radius: 14px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 600;
    -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    color: black;
}
.explore-place-tag:hover {
    color: white;
    background-color: black;
    text-decoration: none;
}
.logout-button, .login-button {
    display: inline-block;
    cursor: pointer;
    color: black !important;
    text-decoration: none !important;
}
.alt-link {
    color: black !important;
    text-decoration: none !important;
    font-weight: 600;
}
.header {
    border-bottom: solid black 3px;
    margin-top: 10px;
    margin-bottom: 8px;
}
.site-title {
    display: inline-block;
    font-size: 14pt;
    font-weight: bold;
    color: black !important;
    text-decoration: none !important;
}
.user-status {
    float: right;
}
.footer {
    margin-top: 25px;
    border-top: solid black 3px;
    margin-bottom: 30px;
}
.map-container {
  height: 100%;
}
.info-window {
  color: black;
}
.info-header {
  font-weight: bold;
  margin-bottom: 5px;
}
.info-vicinity {
  margin-bottom: 8px;
}
.record-visit {
  margin-top: 8px;
}

.marker1 {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}


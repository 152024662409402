@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
.drawer-container {
    margin-left: 10px;
    justify-content: center;
}
.polaroid-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-right: 15px;
}
.img-container {
    max-width: 520px;
    max-height: 500px;
    border: solid 1px lightgray;
    margin: 10px;
    padding: 20px;
    box-shadow: 7px 6px 12px 5px grey;
    background: white;
}
.img-display {
    width: 480px;
    height: 360px;
}
.img-description {
    height: 100px;
    position: relative;
    font-family: 'Permanent Marker', cursive;
    color: black;
    margin-left: 15px;
    margin-right: 15px;
}
.img-description-text-sm {
    position: absolute;
    margin-top: 36px;
}
.img-description-text-md {
    position: absolute;
    margin-top: 22px;
}
.loading-wheel {
    font-size: 20pt;
    font-weight: 600;
    height: 320px;
    margin-top: 215px;
}
.text-size-md {
    font-size: 1.6em;
}
.text-size-sm {
    font-size: 1.5em;
}
.text-size-xs {
    font-size: 1.3em;
}
@media only screen and (max-width: 768px) {
    .text-size-md {
        font-size: 1.4em;
    }
    .text-size-sm {
        font-size: 1.2em;
    }
    .text-size-xs {
        font-size: 1.0em;
    }
    .drawer-container {
        margin-left: 0px;
    }
}
@media only screen and (max-width: 600px) {
    .img-container {
        box-shadow: 3px 4px 12px 1px grey;
        padding: 15px;
    }
    .img-display {
        width: 320px;
        height: 240px;
    }
    .img-description-text-sm {
        margin-top: 25px;
    }
    .img-description-text-md {
        margin-top: 20px;
    }
}
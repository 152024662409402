.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-title {
  display: inline-block;
  font-size: 14pt;
  font-weight: bold;
}
.user-status {
  float: right;
}
.alt-link {
  color: black !important;
  text-decoration: none !important;
  font-weight: 600;
}
.display-name, .logout-button {
  text-decoration: underline;
  cursor: pointer;
}
.page-title {
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 10px;
}
.logout-button, .login-button {
  display: inline-block;
  cursor: pointer;
}





/* Upload */
.img-display-upload-container {
  height: 100%;
  width: 100%;
  border: solid 1px #c3c3c3;
  text-align: center;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-img-label {
  font-family: 'Permanent Marker';
  font-size: 2em;
  color: white;
  width: 100%;
  height: 100%;
  padding-top: 125px;
  cursor: pointer;
  font-weight: 600;
}
.upload-img-label-hidden {
  position: relative;
  top: -315px;
  width: 100%;
  height: 100%;
}
.upload-label-font {
  font-weight: 600;
}
.upload-img-background {
  width: 420px;
  height: 315px;
  background-color: black;
}
@media only screen and (max-width: 768px) {
    .upload-img-background {
      width: 352px;
      height: 264px;
    }
    .upload-img-label {
        padding-top: 95px;
    }
    .upload-img-label-hidden {
      top: -264px;
    }
}
.required {
  color: red;
}




/* Photo */
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
.photo-fact {
  margin-top: 10px;
  border-bottom: solid 1px black;
}
.photo-fact-name {
  font-weight: 400;
  margin-right: 6px;
}
.photo-fact-detail {
  font-family: 'Itim', cursive;
  font-size: 1.2em;
}
.photo-note-book {
  background: lightyellow;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  box-shadow: 5px 6px 13px 2px grey;
}
.photo-img {
  box-shadow: 5px 6px 13px 2px grey;
  cursor: pointer;
}
.admin-panel {
  margin-top: 20px;
}
.modal-dialog {
  margin: 0px !important;
  border: none !important;
  max-width: initial;
}
.modal-content {
  width: 100% !important;
  /*height: 100% !important;*/
  margin: 0px !important;
  border: none !important;
  max-width: initial;
  background-color: black;
}
.modal-backdrop.fade {
  opacity: 1 !important;
}
.photo-card {
  min-height: 547.5px;
}
@media only screen and (max-width: 768px) {
  .photo-note-book {
    box-shadow: 5px 6px 12px 1px grey;
  }
  .photo-img {
    box-shadow: 5px 6px 12px 1px grey;
  }
  .photo-card {
    min-height: 1px;
  }
}

.app-min-height {
  min-height: 585px;
}
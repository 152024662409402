.explore-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}
.explore-card {
    margin-bottom: 15px;
}
.explore-place-tag {
    border: solid 2px black;
    border-radius: 14px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 600;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    color: black;
}
.explore-place-tag:hover {
    color: white;
    background-color: black;
    text-decoration: none;
}
.map-container {
  height: 100%;
}
.info-window {
  color: black;
}
.info-header {
  font-weight: bold;
  margin-bottom: 5px;
}
.info-vicinity {
  margin-bottom: 8px;
}
.record-visit {
  margin-top: 8px;
}

.marker1 {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
